import Container from 'components/container'
import Img, { FluidObject } from 'gatsby-image'
import { getRegionalLink, isJapanese } from 'helpers/locale'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { WholesaleH3, WholesaleP } from 'styles/base'

import { ContentfulLink } from "../../types/graphql-types"
import Link from "../atoms/link"


const StyledFeatures = styled.div`
  ${({ theme }): CSSProp => css`
    margin: 5rem 0;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 7rem 0;
    }
    h2 {
      text-align: center;
      font-size: 1.8rem;
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      margin: 0 0 6rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 2.4rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 3rem;
      }
    }
    .row {
      display: grid;
      grid-gap: 5rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        max-width: 70rem;
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        max-width: none;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 4rem;
      }
    }
  `}
`

const Feature = styled(Link)`
  ${({ theme }): CSSProp => css`
    text-align: center;
    font-size: ${theme.font.fontSizeBodyDefault};
    font-weight: ${theme.font.fontWeightLight};

    outline: none;
    text-decoration: none;
    position: relative;
    box-shadow: 0 5px 0 0 rgba(43, 25, 22, 0);
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    padding: 2rem;

    &::after {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 5px;
      box-shadow: 0 5px 21px 0 rgba(43, 25, 22, 0.12);
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          opacity: 1;
        }
      }
    }

    h3 {
      margin-bottom: 2.5rem;
    }
    .gatsby-image-wrapper {
      width: 8rem;
      margin: 0 auto 3rem;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-bottom: 5rem;
      }
    }
  `}
`

type FeaturesProps = {
  title?: string
  items: [
    {
      id: string
      image: {
        fluid: FluidObject
      }
      title: string
      body: string
      link?: ContentfulLink | any
    }
  ]
}

const WholesaleFeatures = ({ title, items }: FeaturesProps): JSX.Element => {
  return (
    <StyledFeatures>
      <Container>
        {title && <h2>{title}</h2>}
        <div className="row">
          {items.map(item => {
            let url = ''

            if (item.link) {
              const linkType = item.link.internal.type
              switch (linkType) {
                case 'ContentfulRegionalLink':
                  url = getRegionalLink(item.link)
                  break

                case 'ContentfulLink':
                  url = item.link.link
                  break

                default:
                  console.error(`Features Link: ${linkType} Type not handled`)
              }
            }
            return (
              <Feature key={item.id} to={ item.link ? url : null} className={item.link ? 'has-link' : ''}>
                <Img fluid={item.image.fluid} />
                <WholesaleH3>{item.title}</WholesaleH3>
                <div><WholesaleP>{item.body}</WholesaleP></div>
              </Feature>
            )
          })}
        </div>
      </Container>
    </StyledFeatures>
  )
}

export default WholesaleFeatures
